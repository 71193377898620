* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Inter', sans-serif;
  height: 100%;
  width: 100%;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Regular.woff');
}

#root {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
